<template>
  <div class="div">
    <Navbar />
    <main id="main" class="main">
            <section class="home-banner-section inner-banner cover-bg"
            id="banner"
            >
                <div class="banner-caption">
                    <div class="banner-caption-content text-white">
                        <div class="banner-caption-text d-flex w-100 align-items-center flex-warp h-100 py-5">
                            <div class="container">
                                <div class="banner-text">
                                    <h1 class="title-border">FAQ's</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="curved-section-main">
                <!-- ! Section start -->
                <section class="section curved-section no-min-height">
                    <div class="section-shape section-shape-top">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="84.752" viewBox="0 0 1920 84.752" class="shape-1">
                            <g transform="translate(0 -0.248)">
                                <g transform="translate(0 -402)">
                                    <path
                                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                                        fill-rule="evenodd" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="container section-container-padding ">
                        <div class="section-top-bar d-flex">
                            <div class="section-top-bar-container">
                                <h2 class="section-title">Frequently <span>Asked Questions</span></h2>
                            </div>
                        </div>
                        <div class="accordion accordion-faq" id="faq-list">
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-1">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" rol="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-1" aria-expanded="false" aria-controls="faq-collapse-1">
                                        Is my phone supported?
                                    </a>
                                </div>
                                <div id="faq-collapse-1" class="accordion-collapse collapse" aria-labelledby="faq-heading-1" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p><strong>Our Mobile Apps are supported on the following platforms:</strong></p>
                                        <ul class="custom-list">
                                            <li>iOS 11.0 or higher (iPhone, iPod touch, and/or iPad)</li>
                                            <li>Android 8.0 and higher</li>
                                        </ul>
                                        <p><strong>Our Mobile Web App version supports the following browser:</strong></p>
                                        <ul class="custom-list">
                                            <li>Google Chrome on Android OS 2.3.x and 4.x.x</li>
                                            <li>Safari on iOS 7.0 and above</li>
                                            <li>Internet Explorer on Windows Phone 7</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-2">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-2" aria-expanded="false" aria-controls="faq-collapse-2">
                                        Is the Mobile App secure?
                                    </a>
                                </div>
                                <div id="faq-collapse-2" class="accordion-collapse collapse" aria-labelledby="faq-heading-2" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Yes! All critical information is encrypted in every transaction run through the Apps and the Mobile Web App, and no personal information is stored on your mobile device. However, mobile devices do offer you the
                                            ability to store your login information for apps installed on the device. If you choose to store your login information, any person who has access to your mobile device can access your account.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-3">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-3" aria-expanded="false" aria-controls="faq-collapse-3">
                                        What features does the Mobile App have?
                                    </a>
                                </div>
                                <div id="faq-collapse-3" class="accordion-collapse collapse" aria-labelledby="faq-heading-3" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Both the Mobile Apps and the Mobile Web App give you the ability to view your accounts, modify, upload, history or maintain your account for alerts and reminders, and contact us via email or phone.</p>
                                        <p>Once you've installed a Mobile App on your phone, you'll also can receive push notifications.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-4">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-4" aria-expanded="false" aria-controls="faq-collapse-4">
                                        How do I get the Mobile App for my phone?
                                    </a>
                                </div>
                                <div id="faq-collapse-4" class="accordion-collapse collapse" aria-labelledby="faq-heading-4" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Simply look for our name VAXTRAX in the App Store or in the Android Market Place. In the Android Market, if you can't find our App, that likely means your phone is not supported - see the list of supported operating
                                            systems.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-5">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-5" aria-expanded="false" aria-controls="faq-collapse-3">
                                        Do I have to buy the Mobile App?
                                    </a>
                                </div>
                                <div id="faq-collapse-5" class="accordion-collapse collapse" aria-labelledby="faq-heading-5" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>No. Our Mobile App is completely free to download and install.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-6">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-6" aria-expanded="false" aria-controls="faq-collapse-6">
                                        How current is the account information I see in the Mobile App?
                                    </a>
                                </div>
                                <div id="faq-collapse-6" class="accordion-collapse collapse" aria-labelledby="faq-heading-6" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The information you see in the Mobile App and in the Mobile Web App is shown in real-time, so it's always accurate. However, if you keep your Mobile App or Mobile Web App open for an extended period, you should
                                            refresh the page by selecting a new option to ensure the information is still current.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-7">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-7" aria-expanded="false" aria-controls="faq-collapse-7">
                                        How do I sign up for push notifications?
                                    </a>
                                </div>
                                <div id="faq-collapse-7" class="accordion-collapse collapse" aria-labelledby="faq-heading-7" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The first time you launch the App after installing it on your mobile device, you'll be asked whether you want to enable push notifications for our App on your device. Select OK to enable push notifications
                                        </p>
                                        <p> If you have our App installed on multiple devices, don't forget to enable push notifications for your accounts on each device, as the push notification settings for each account are device specific.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-8">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-8" aria-expanded="false" aria-controls="faq-collapse-8">
                                        Can I change my picture or information?
                                    </a>
                                </div>
                                <div id="faq-collapse-8" class="accordion-collapse collapse" aria-labelledby="faq-heading-8" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Once setup your account certain data cannot be modified until expired or you provide VTG with documentations for validating the changes (Passport, Date of Birth, Picture, Driver’s License, Identification etc.)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-9">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-9" aria-expanded="false" aria-controls="faq-collapse-9">
                                        Can I purchase a Plastic card or Visa sticker?
                                    </a>
                                </div>
                                <div id="faq-collapse-9" class="accordion-collapse collapse" aria-labelledby="faq-heading-9" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>If your country, airport, or event planner have an agreement with VAXTRAX to supply these items, then it will be available for purchase online and within the app
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-10">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-10" aria-expanded="false" aria-controls="faq-collapse-10">
                                        How do I upload my vaccines and test?
                                    </a>
                                </div>
                                <div id="faq-collapse-10" class="accordion-collapse collapse" aria-labelledby="faq-heading-10" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Select the Vaccine or Test icon from your profile, select upload and add image from your photo gallery. Add the batch, date, and select the type. Once uploaded it will not considered as certify.
                                        </p>
                                        <p>Also, you can login to your Health records and capture the screen of the test or vaccine certificate.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-11">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-11" aria-expanded="false" aria-controls="faq-collapse-11">
                                        Can I upload other vaccines and test beside COVID?
                                    </a>
                                </div>
                                <div id="faq-collapse-11" class="accordion-collapse collapse" aria-labelledby="faq-heading-11" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Yes, Select the Health Information and upload the other test and vaccines.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-12">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-12" aria-expanded="false" aria-controls="faq-collapse-12">
                                        Can I see my friend’s status?
                                    </a>
                                </div>
                                <div id="faq-collapse-12" class="accordion-collapse collapse" aria-labelledby="faq-heading-12" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Yes. Create a group and invite your friends when they accept the notifications you will see their status.</p>
                                        <p>Also, can be used for Get-Togethers, weddings, parties etc.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-13">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-13" aria-expanded="false" aria-controls="faq-collapse-13">
                                        Can I use the VAXTRAX app for traveling?
                                    </a>
                                </div>
                                <div id="faq-collapse-13" class="accordion-collapse collapse" aria-labelledby="faq-heading-13" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Yes. We are currently developing the integration to airlines travel schedules, once completed and the airline accepts the VTG app.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-14">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-14" aria-expanded="false" aria-controls="faq-collapse-15">
                                        Where can VAXTRAX app be used?
                                    </a>
                                </div>
                                <div id="faq-collapse-14" class="accordion-collapse collapse" aria-labelledby="faq-heading-14" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>VAXTRAX app can be used at any VTG vendor to display your status.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-15">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-15" aria-expanded="false" aria-controls="faq-collapse-14">
                                        Can I add family member to my VAXTRAX App?
                                    </a>
                                </div>
                                <div id="faq-collapse-15" class="accordion-collapse collapse" aria-labelledby="faq-heading-15" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Yes. If your family member is under 13 years, they can be added to your app as a member.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-16">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-16" aria-expanded="false" aria-controls="faq-collapse-16">
                                        What is VAXTRAX global?
                                    </a>
                                </div>
                                <div id="faq-collapse-16" class="accordion-collapse collapse" aria-labelledby="faq-heading-16" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Vaxtrax Global is a user-friendly mobile app that allows users to track and administer vaccination status and COVID-19 test results, and other vaccination records. It simplifies the process by enabling users to
                                            track their family members' vaccination history and test results in real-time.</p>
                                        <p>VAXTRAX GLOBAL is a free app that helps users validate immunization status and Covid test results by scanning a QR code wherever the VAXTRAX app is accepted. This app will reduce the risk of fraud and minimize unnecessary
                                            costs associated with medical errors.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-17">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-17" aria-expanded="false" aria-controls="faq-collapse-17">
                                        Who has developed VAXTRAX app?
                                    </a>
                                </div>
                                <div id="faq-collapse-17" class="accordion-collapse collapse" aria-labelledby="faq-heading-17" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The VAXTRAX app has been designed and developed by JAB Companies Inc., an engineering and technology company, to provide instant, accurate, and transparent access to vaccine and COVID test results.</p>

                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-17">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-17" aria-expanded="false" aria-controls="faq-collapse-17">
                                        How VAXTRAX GLOBAL app works?
                                    </a>
                                </div>
                                <div id="faq-collapse-17" class="accordion-collapse collapse" aria-labelledby="faq-heading-17" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The VTG app allows users to upload vaccination and COVID test information by a unique QR code. This provides the highest level of security because the information cannot be accessed without the QR code. The app
                                            stores and displays the type of COVID vaccines, the number of doses, and when the vaccines were administered (Date/Time). The VTG app also allows users to track the results of COVID tests, other tests and vaccines
                                            administered. In addition, using VAXTRAX GLOBAL app, users can track when their vaccines and tests are expiring. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-18">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-18" aria-expanded="false" aria-controls="faq-collapse-18">
                                        How can you download VAXTRAX GLOBAL app?
                                    </a>
                                </div>
                                <div id="faq-collapse-18" class="accordion-collapse collapse" aria-labelledby="faq-heading-18" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The VAXTRAX GLOBAL APP can be used on any device with an iOS or Android operating system. It is available for free download from both the Apple Store and Google Play Store. The website also has links to download
                                            the app directly from its site. Join us in preventing the pandemic, helping the communities by downloading and utilizing the app.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-19">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-19" aria-expanded="false" aria-controls="faq-collapse-19">
                                        How can you access VAXTRAX GLOBAL app features?
                                    </a>
                                </div>
                                <div id="faq-collapse-19" class="accordion-collapse collapse" aria-labelledby="faq-heading-19" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The VAXTRAX Global App is designed to allow users for upload and store COVID vaccine and test certificates on the app. Sign up to create a new account by entering your mobile number, email, and password. Once you
                                            have entered the required information and agreed to our terms, conditions, and privacy policy, click on "Register." </p>
                                        <p>If you already have an account, use your mobile number or email, and password to login. Edit profile, enter, or upload the related data to the COVID vaccine and test certificates, other test and vaccine certificates,
                                            and access many other features. The app also enables users to invite others to join groups for get-together, public events, special occasions, etc.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-20">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-20" aria-expanded="false" aria-controls="faq-collapse-20">
                                        Who should use VAXTRAX app?
                                    </a>
                                </div>
                                <div id="faq-collapse-20" class="accordion-collapse collapse" aria-labelledby="faq-heading-20" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>VAXTRAX GLOBAL is a quick, efficient, and reliable solution for verifying your vaccination and or COVID test status. Anyone traveling or attending a public event or getting together can download the VTG app on their
                                            mobile phones and tablets for verifying their status of vaccinations and or COVID tests by scanning the QR Code. VAXTRAX reduces the time for queues on validation of the COVID vaccination card and or paper copy
                                            of the test certificate. This app is compatible with iPhone, Android, and web. Using the VTG app eliminates the fraudulent or fake documents. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-21">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-21" aria-expanded="false" aria-controls="faq-collapse-21">
                                        Where to use VAXTRAX GLOBAL app?
                                    </a>
                                </div>
                                <div id="faq-collapse-21" class="accordion-collapse collapse" aria-labelledby="faq-heading-21" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>You can use The VAXTRAX GLOBAL app at multiple locations where the VTG app is accepted, including offices, public gatherings, hospitals, schools, airports, events, concerts, stadiums etc.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-22">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-22" aria-expanded="false" aria-controls="faq-collapse-22">
                                        What features does the VAXTRAX GLOBAL app offer? 
                                    </a>
                                </div>
                                <div id="faq-collapse-22" class="accordion-collapse collapse" aria-labelledby="faq-heading-22" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The app allows you to create and manage your profile, upload vaccine and COVID test results, create a digital vaccination card, and link parent and child via one QR code. You can also upload travel information,
                                            create groups to invite friends to a public gathering, and manages the quarantine and tracing process if you tested positive for the COVID virus.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-23">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-23" aria-expanded="false" aria-controls="faq-collapse-23">
                                        Is it necessary to provide personal information to register?
                                    </a>
                                </div>
                                <div id="faq-collapse-23" class="accordion-collapse collapse" aria-labelledby="faq-heading-23" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>When creating an account or uploading records for the first time, you must enter the personal information to sign up to access app features. On Sign up, you will be required to provide both email and mobile number
                                            along with your names, date of birth, gender, identification, and citizenship information.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-24">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-24" aria-expanded="false" aria-controls="faq-collapse-24">
                                        Why QR code?
                                    </a>
                                </div>
                                <div id="faq-collapse-24" class="accordion-collapse collapse" aria-labelledby="faq-heading-24" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>When you Sign up, it confirms the data and generates a unique QR code that you can show at airports, events, restaurants or other locations to verify your vaccines and tests status once uploaded.</p>
                                        <p>The unique QR code is beneficial because even if someone loses the paper copy, they will always have access to the documents by the VTG app! It's no longer necessary to search through files for your records since
                                            everything is available online anytime, anywhere!</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-25">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-25" aria-expanded="false" aria-controls="faq-collapse-25">
                                        What are the benefits of using the VAXTRAX GLOBAL app? 
                                    </a>
                                </div>
                                <div id="faq-collapse-25" class="accordion-collapse collapse" aria-labelledby="faq-heading-25" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>We at JAB have created an innovative app that allows users to upload information about their vaccines and COVID tests to store them digitally on our secure platform. </p>
                                        <p>This eliminates the need for paper copies that are inefficient and can be lost or misplaced. </p>
                                        <p>It also, reduces process time and long queues at airports, concerts, and offices etc. With the VTG App, vendors can verify that the user is Coronavirus negative, other vaccines administered by scanning the QR code.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-26">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-26" aria-expanded="false" aria-controls="faq-collapse-26">
                                        Can you link all family members via one QR code?
                                    </a>
                                </div>
                                <div id="faq-collapse-26" class="accordion-collapse collapse" aria-labelledby="faq-heading-26" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The Parent-child module allows you to combine your children's and older parents' codes for convenience when traveling together. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-27">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-27" aria-expanded="false" aria-controls="faq-collapse-27">
                                        Can I create a group and invite friends to an event or a public gathering?
                                    </a>
                                </div>
                                <div id="faq-collapse-27" class="accordion-collapse collapse" aria-labelledby="faq-heading-26" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>VAXTRAX app has an exciting feature that lets you invite friends to concerts or public events or get-togethers and view your friends' vaccine and COVID test status.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-28">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-28" aria-expanded="false" aria-controls="faq-collapse-28">
                                        Who are medical partners?
                                    </a>
                                </div>
                                <div id="faq-collapse-28" class="accordion-collapse collapse" aria-labelledby="faq-heading-28" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Profile is created by our partnered labs, clinics, hospitals, and pharmacies that administer the COVID test and vaccines globally. The VTG team confirms the identity of the healthcare provider administering the
                                            vaccine or COVID test process.</p>
                                        <p>The certificates can then be uploaded to the user's health record on the app or website by completing details such as phone number or email address, date of birth, names, date of visit, and name of the medical facility
                                            that administered the COVID vaccine and or test. Medical facilities can also scan user’s QR codes and upload test certificates if available.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-29">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-29" aria-expanded="false" aria-controls="faq-collapse-29">
                                        Who are vendors?
                                    </a>
                                </div>
                                <div id="faq-collapse-29" class="accordion-collapse collapse" aria-labelledby="faq-heading-29" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>A vendor is a company that scans the user’s QR Code to display the vaccination and COVID test status. The VTG team will validate the vendor's credentials before granting access; it's not instant access like a user
                                            registration account. Contact us at support@vaxtraxglobal.com for more information on becoming a vendor.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-30">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-30" aria-expanded="false" aria-controls="faq-collapse-30">
                                        Can I get a digital vaccine card via app?
                                    </a>
                                </div>
                                <div id="faq-collapse-30" class="accordion-collapse collapse" aria-labelledby="faq-heading-30" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The process is simple, secure, and takes only a few minutes. Once you submit your profile details, a digital vaccine card will be created and displayed under My Vaccine Card with all your details.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-31">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-31" aria-expanded="false" aria-controls="faq-collapse-31">
                                        Does the app post a notification when the qr code is scanned?
                                    </a>
                                </div>
                                <div id="faq-collapse-31" class="accordion-collapse collapse" aria-labelledby="faq-heading-31" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>TUsers will get a notification when VTG vendors or lab/clinic scan their QR code. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-31">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-31" aria-expanded="false" aria-controls="faq-collapse-31">
                                        Can users accept or block vendors?
                                    </a>
                                </div>
                                <div id="faq-collapse-31" class="accordion-collapse collapse" aria-labelledby="faq-heading-31" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Yes, users can accept or block vendors once received notifications.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-32">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-32" aria-expanded="false" aria-controls="faq-collapse-32">
                                        How do I upload my COVID test?
                                    </a>
                                </div>
                                <div id="faq-collapse-32" class="accordion-collapse collapse" aria-labelledby="faq-heading-32" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Click on Test History to upload COVID test results. The Upload page will display for uploading test results. Select the Upload File button to upload your test certificates in PDF, JPG, or PNG format (Max 5MB size).
                                            Add the batch number, the date, lab or clinic name, and then click "SAVE." It will then display your latest Covid results in the Test History section.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-33">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-33" aria-expanded="false" aria-controls="faq-collapse-33">
                                        How is my privacy protected in VAXTRAX app?
                                    </a>
                                </div>
                                <div id="faq-collapse-33" class="accordion-collapse collapse" aria-labelledby="faq-heading-33" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>We maintain the confidentiality of your data. Third parties are not given access to your private information. All the data collected through the app is stored on AWS cloud storage with secure encryption. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-34">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-34" aria-expanded="false" aria-controls="faq-collapse-34">
                                        Can someone access my vaccination records or the COVID test results without my consent? 
                                    </a>
                                </div>
                                <div id="faq-collapse-34" class="accordion-collapse collapse" aria-labelledby="faq-heading-34" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>A person can only view your COVID vaccine or test results if you allow them to scan your QR Code or provide credentials to your mobile device or web.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-35">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-35" aria-expanded="false" aria-controls="faq-collapse-35">
                                        Is there any cost involved?
                                    </a>
                                </div>
                                <div id="faq-collapse-35" class="accordion-collapse collapse" aria-labelledby="faq-heading-35" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>No, you do not have to pay anything to download and use the app. There are special features that may require a fee.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-36">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-36" aria-expanded="false" aria-controls="faq-collapse-36">
                                        Why should I purchase a plastic card if I have already uploaded my vaccination and tests certificates? 
                                    </a>
                                </div>
                                <div id="faq-collapse-36" class="accordion-collapse collapse" aria-labelledby="faq-heading-36" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Plastic cards can be purchased as a backup if the user is unable to connect to the network or if they lost or misplace mobile devices. Cards must be approved by your country, the event organizer, or the employer
                                            before can be purchased.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-37">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-37" aria-expanded="false" aria-controls="faq-collapse-37">
                                        Is it possible to view my entire vaccination history or just the doses for COVID-19? 
                                    </a>
                                </div>
                                <div id="faq-collapse-37" class="accordion-collapse collapse" aria-labelledby="faq-heading-37" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Along with COVID-19 vaccination and test records, VAXTRAX displays your entire vaccination history and tests uploaded. Select the Health Information and upload the other vaccines or tests. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-38">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-38" aria-expanded="false" aria-controls="faq-collapse-38">
                                        Is VAXTRAX app a vaccine passport? 
                                    </a>
                                </div>
                                <div id="faq-collapse-38" class="accordion-collapse collapse" aria-labelledby="faq-heading-38" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>VAXTRAX intends to let you access your vaccine and COVID-19 test records digitally for your convenience.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-39">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-39" aria-expanded="false" aria-controls="faq-collapse-39">
                                        Can I download the app in a language other than english?
                                    </a>
                                </div>
                                <div id="faq-collapse-39" class="accordion-collapse collapse" aria-labelledby="faq-heading-39" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>The app is available in five languages: English, Spanish, French, Portuguese, and Arabic</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-40">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-40" aria-expanded="false" aria-controls="faq-collapse-40">
                                        How come my vaccination details are still listed as pending in the app?
                                    </a>
                                </div>
                                <div id="faq-collapse-40" class="accordion-collapse collapse" aria-labelledby="faq-heading-40" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>If you have uploaded your vaccination record, please be patient. The verification process (for a fee) of the vaccination or test takes some time. The VAXTRAX app will display your vaccination records once they have
                                            been verified. The vaccine or test is certified instantly if uploaded by the medical institution.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-41">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-41" aria-expanded="false" aria-controls="faq-collapse-41">
                                        Can I change my picture or information?
                                    </a>
                                </div>
                                <div id="faq-collapse-41" class="accordion-collapse collapse" aria-labelledby="faq-heading-41" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Once setup your account certain data cannot be modified until expired or you provide VTG with documentations for validating the changes (Passport, Date of Birth, Picture, Driver’s License, Identification etc.)</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="faq-heading-42">
                                    <a href="javascript:void(0)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapse-42" aria-expanded="false" aria-controls="faq-collapse-42">
                                        Can I see my friend’s status?
                                    </a>
                                </div>
                                <div id="faq-collapse-42" class="accordion-collapse collapse" aria-labelledby="faq-heading-42" data-bs-parent="#faq-list">
                                    <div class="accordion-body">
                                        <p>Yes. Create a group and invite your friends when they accept the notifications you will see their status.</p>
                                        <p>Also, can be used for Get-Togethers, weddings, parties etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- ! Section end -->
            </div>
            <!-- ! Section start -->
            <section id="lets_talk" class="section curved-section lets-talk-section cover-bg text-white" style="background-image:url(../../assets/images/contact-blockimage.jpg);">
                <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
                <div class="container section-container-padding">
                    <div class="section-top-bar d-flex">
                        <div class="section-top-bar-container">
                            <h2 class="section-title title-white">Let's <span>talk</span></h2>
                        </div>

                    </div>
                    <div class="contact-box">
                        <form action="forms/contact.php" method="post" role="form" class="php-email-form h-100">
                            <div class="row">
                                <div class="col-md-6 form-group mb-4">
                                    <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                                    <div class="validate"></div>
                                </div>
                                <div class="col-md-6 form-group mb-4">
                                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email">
                                    <div class="validate"></div>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
                                <div class="validate"></div>
                            </div>
                            <div class="form-group mb-4">
                                <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                                <div class="validate"></div>
                            </div>
                            <div class="mb-3">
                                <div class="loading">Loading</div>
                                <div class="error-message"></div>
                                <div class="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div class="text-center"><button class="btn btn-orange" type="submit">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <!-- ! Section end -->

        </main>
    <!-- ! Main end -->
    <!-- <div class="push"></div> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";


import "@/assets/js/wow.js";
import "@/assets/js/venobox.min.js";
import "@/assets/js/validate.js";
import "@/assets/js/global.js";
export default {
  components: {
    Navbar,
//Footer,
  }, mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/bootstrap-icons.css";
@import "../../assets/css/screen.css";
@import "../../assets/css/venobox.min.css";


#lets_talk {
  background-image: url("../../assets/images/contact-blockimage.jpg") !important;
}
#banner {
  background-image: url("../../assets/images/faq-banner.jpg") !important;
}
</style>
